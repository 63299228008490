
import React from 'react';

const AboutUs = () => {
	return (
			<div id={"main-text-container"}>
				<div className={"description-heading"}>
					<h2>About Us</h2>
				</div>
				<p>Located in California's Orange County, we take pride in our affordable housing and outstanding service.</p><br />
				<p>As a small and growing company, we dedicate ourselves to ensuring our customers are happy with the quality of their homes.</p><br />
				<p>It's as simple as that!</p>
			</div>
	);
}

export default AboutUs;