
import '../App.css';
import PropertyTiles from "./PropertyTiles";

const Availability = () => {
	return (
			<main>
				<section>
					<PropertyTiles />
				</section>
			</main>
	);
}

export default Availability;
