
import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";

const highlightedStyle = {
	color: "#F5F5F5",
	backgroundColor: "#0C3649",
	pointerEvents: "none"
};

const Ul = styled.div`
  display: none;
  list-style: none;
  flex-flow: row nowrap;
	overflow: hidden;
	
  @media (max-width: 900px) {
    display: flex;
    position: fixed;
    flex-flow: column nowrap;
    top: 0;
    right: 0;
    width: 275px;
    height: 110vh;
		z-index: 10;
    background-color: gainsboro;
    box-shadow: 0 0.2em 0.2em rgba(0, 0, 0, 0.15), 0 0.2em 0.2em rgba(0, 0, 0, 0.20);
    opacity: 0.95;
    padding: 12.5vh 0 0 0;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
    transition: 0.3s transform ease-in-out;
`;

const StyledLink = styled(Link)`
  color: #0C3649;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  padding: 1em;
	
  :hover {
    color: #F5F5F5;
    background-color: #0C3649;
		transition: 0.2s all ease-in-out;
  }
`;

const SlideNav = (props) => {
	return (
			<Ul open={props.open}>
				{props.tabs.map((tabName) => {
					return <StyledLink to={"/" + tabName}
														 style={(props.tabName === "" && tabName === "home") ? highlightedStyle :
																 (tabName === props.tabName) ? highlightedStyle : null}
														 key={tabName}>{tabName.toUpperCase()}</StyledLink>
				})}
			</Ul>
	)
}

export default SlideNav;
