
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";
import SlideNav from "./SlideNav";
import {propertyData} from "../../Resources/PropertyData";

const StyledBurger = styled.div`
  display: none;
  position: fixed;
	top: 0;
	right: 20px;
  width: 2em;
  height: 2em;
  z-index: 1000;
	
  @media (max-width: 900px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
    cursor: pointer;
    margin: 4vh 1rem;
  }
  div {
    width: 2em;
    height: 0.25rem;
    background-color: ${({ open }) => open ? "#072F5F" : "#072F5F"};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => open ? "rotate(45deg)" : "rotate(0)"};
    }
    &:nth-child(2) {
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? "rotate(-45deg)" : "rotate(0)"};
    }
  }
`;

/**
 * The Navbar represents the navigational toolbar.
 * On desktop devices, the Navbar displays all the tabs without collapsing them. On mobile devices,
 * the Navbar should display a hamburger icon which, when pressed, will extend to show a condensed menu.
 * @returns {JSX.Element} the html elements representing the left tabs of the navbar
 * @constructor `props` contains a tabName - the name of the page passed in by the parent component, and
 *              a tabs - the array of Strings representing the tabs"" names
 */
const Header = () => {
	const currentRoute = useLocation().pathname;
	const [open, setOpen] = useState(false);
	let tabs = ["home", "availability", "contact"];

	useEffect(() => {
		propertyData.forEach(property => {
			new Image().src = property.imageSrc;
		});
	}, []);

	return (
			<header id="header-nav">
				<div id="company-header">
					<img id="company-logo" src="https://imgur.com/AiHqX0O.jpg" alt="Sure & Pure LLC company logo."/>
				</div>
				<nav>
					<>
						<StyledBurger open={open} onClick={() => setOpen(!open)}>
							{/* Render the three lines of the hamburger icon */}
							<div />
							<div />
							<div />
						</StyledBurger>
						<SlideNav open={open}
											tabs={tabs}
											tabName={currentRoute.substring(1)} />
					</>
						<div id={"nav-links"}>
							<Link to={"/"}
										className={"nav-link hover-underline-animation" +
												(currentRoute === "/" ? " active-nav-link" : "")}>
								HOME
							</Link>
							<Link to={"/Availability"}
										className={"nav-link hover-underline-animation" +
												(currentRoute === "/Availability" ? " active-nav-link" : "")}>
								AVAILABILITY
							</Link>
							<Link to={"/Contact"}
										className={"nav-link hover-underline-animation" +
												(currentRoute === "/Contact" ? " active-nav-link" : "")}>
								CONTACT
							</Link>
						</div>
				</nav>
			</header>
	);
}

export default Header;
