
import React from 'react';

const FormHeader = () => {
	return (
			<div id={"contact-header"}>
				<h1>How can we help?</h1>
				<p>Addressing your concerns is important to us.</p>
			</div>
	);
}

export default FormHeader;