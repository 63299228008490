
import React, {useState} from 'react';
import { propertyData } from "../Resources/PropertyData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

const HouseImageSlider = () => {
  const [current, setCurrent] = useState(0);
  const length = propertyData.length;

  // return null if propertyData returns no data
  if (!Array.isArray(propertyData) || length <= 0) {
    return null;
  }

  const previousSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1)
  }

  return (
      <div className={"slides-container"}>
        <FontAwesomeIcon icon={faChevronLeft} id={"slides-button-left"} className={"slides-button"} size={"3x"}
                         onClick={previousSlide} />
        <FontAwesomeIcon icon={faChevronRight} id={"slides-button-right"} className={"slides-button"} size={"3x"}
                         onClick={nextSlide} />
        {propertyData.map((slide, index) => {
          return (
            <div className={index === current ? 'slide-active' : 'slide'} key={index}>
              {index === current &&
                <img className={"household-property-img"} src={slide.imageSrc} alt={slide.imageAlt} />}
            </div>
          )
        })}
      </div>
  );
}

export default HouseImageSlider;
