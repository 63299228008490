
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBed, faSink, faRulerCombined }
	from "@fortawesome/free-solid-svg-icons";
import {propertyData} from "../Resources/PropertyData";

const PropertyTiles = () => {
	let propertyTiles = [];
	let index = 0;

	for (let i = 0; i < propertyData.length; ++i) {
		const currentProperty = propertyData[i];

		propertyTiles.push(
				<div key={index++}
						 className={"property-tile"}>
					<div className={"property-image"}>
						<img src={currentProperty.imageSrc} alt={currentProperty.imageAlt} />
					</div>
					<div className={"property-title"}>
						<p className={"description-title"}>{currentProperty.availabilityStatus}</p>
					</div>
					<div className={"property-meta"}>
						<div className={"property-description"}>
							<div className={"description-left-column"}>
								<p className={"description-address"}>{currentProperty.address}</p>
							</div>
							<div className={"description-right-column"}>
								<div className={"property-icon-stats"}>
									<span style={{verticalAlign: "middle"}}>
										<FontAwesomeIcon icon={faBed} className={"description-room-space"} />
									</span>
									<p className={"description-room-space"}>{currentProperty.numOfBedrooms}</p>
								</div>
								<div className={"property-icon-stats"}>
								<span style={{verticalAlign: "middle", alignSelf: "middle"}}>
									<FontAwesomeIcon icon={faSink} className={"description-room-space"} />
								</span>
									<p className={"description-room-space"}>{currentProperty.numOfBathrooms}</p>
								</div>
								<div className={"property-icon-stats"}>
								<span style={{verticalAlign: "middle"}}>
									<FontAwesomeIcon icon={faRulerCombined} className={"description-room-space"} />
								</span>
									<p className={"description-room-space"}>{currentProperty.areaSqFt}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
		)
	}

	return (
			<div id={"properties"}>
				{propertyTiles}
			</div>
	);
}

export default PropertyTiles;
