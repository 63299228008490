
import {createRef, useEffect} from 'react'
import {
	createBrowserRouter,
	useLocation,
	useOutlet,
} from 'react-router-dom'
import { CSSTransition, SwitchTransition } from 'react-transition-group'
import { Container } from 'react-bootstrap'
import Home from "./Home/Home";
import Availability from "./Availability/Availability";
import Contact from "./Contact/Contact";
import Footer from "./Nav/Footer";
import {propertyData} from "./Resources/PropertyData";
import './App.css';
import Header from "./Nav/HeaderNavbar/Header";

const routes = [
	{ path: '*', name: 'Default', element: <Home />, nodeRef: createRef() },
	{ path: '/', name: 'Home', element: <Home />, nodeRef: createRef() },
	{ path: '/Availability', name: 'Availability', element: <Availability />, nodeRef: createRef() },
	{ path: '/Contact', name: 'Contact', element: <Contact />, nodeRef: createRef() },
];

function App() {
	const location = useLocation();
	const currentOutlet = useOutlet();
	const { nodeRef } = routes.find((route) => route.path === location.pathname) ?? {};

	function scrollTop() {
		window.scroll({top: 0, left: 0});
	}

	useEffect(() => {
		setTimeout(scrollTop, 300);
	});

	return (
			<>
				<Header />
				<Container>
					<SwitchTransition>
						<CSSTransition
								key={location.pathname}
								nodeRef={nodeRef}
								timeout={300}
								classNames="page"
								unmountOnExit
						>
							{() => (
									<div ref={nodeRef}>
										{currentOutlet}
									</div>
							)}
						</CSSTransition>
					</SwitchTransition>
				</Container>
				<Footer />
			</>
	)
}

const router = createBrowserRouter([
	{
		path: '/',
		element: <App />,
		children: routes.map((route) => ({
			index: route.path === '/',
			path: route.path === '/' ? undefined : route.path,
			element: route.element,
		})),
	},
]);

export default router;
