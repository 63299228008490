
let propertyData = [
  {
    imageSrc: 'https://imgur.com/ZCnseQc.jpg',
    imageAlt: 'First household in Garden Grove.',
    availabilityStatus: "Not Available",
    numOfBedrooms: "6",
    numOfBathrooms: "2",
    areaSqFt: "1,889 sq ft",
    address: "12452 Oertly Dr.\nGarden Grove, CA 92840"
  },
  {
    imageSrc: 'https://imgur.com/Dj3dzN1.jpg',
    imageAlt: 'Second household in Garden Grove.',
    availabilityStatus: "Not Available",
    numOfBedrooms: "6",
    numOfBathrooms: "2",
    areaSqFt: "2,102 sq ft",
    address: "12702 Lamplighter St.\nGarden Grove, CA 92845"
  },
  {
    imageSrc: 'https://imgur.com/9qkkPm9.jpg',
    imageAlt: 'Third household in Garden Grove.',
    availabilityStatus: "Not Available",
    numOfBedrooms: "4",
    numOfBathrooms: "2",
    areaSqFt: "1,304 sq ft",
    address: "11024 Cynthia Cir. #4\nGarden Grove, CA 92843"
  }
];

export { propertyData }