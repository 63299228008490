
import '../App.css';
import {EmailJsForm} from "./EmailJsForm";
import FormHeader from "./FormHeader";

const Contact = () => {
  return (
      <main>
        <section>
          <FormHeader />
          <EmailJsForm />
        </section>
      </main>
   );
}

export default Contact;
