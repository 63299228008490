
import '../App.css';
import HouseImageSlider from "./HouseImageSlider";
import AboutUs from "./AboutUs";

const Home = () => {
  return (
      <main>
        <section>
          <HouseImageSlider />
        </section>
        <section>
          <AboutUs />
        </section>
      </main>
  );
}

export default Home;
