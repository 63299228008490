
import { useRef } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import { emailKeys } from "../Resources/Emailkeys";

let notification;

export const EmailJsForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      emailKeys.SERVICE_ID,
      emailKeys.TEMPLATE_ID,
      form.current,
      emailKeys.PUBLIC_KEY)
      .then((result) => {
        notification = toast.success('Message sent.', {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(result.text);
      }, (error) => {
        toast.error('Error; try again later.', {
          position: "top-center",
          width: 100,
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.log(error.text);
      });

    e.target.reset();
  };

  return (
    <div id="form-container">
      <form id="survey-form" ref={form} onSubmit={ sendEmail }>
        <fieldset id="personal-info">
          <legend id={"header-divider"}>Contact Us</legend>
          <input id="name" type="text" placeholder="Name *" name="name" required />
          <input id="email" type="email" placeholder="E-mail *" name="email" required />
          <input id="telephone" type="tel" placeholder="Telephone (Format: 123-456-7890)"
                 pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" name="phone" />
        </fieldset>
        <fieldset>
          <textarea id="client-message" placeholder="Message *" name="message" required />
        </fieldset>
        <div id="contact-submit">
          <input id="submit" type="submit" value="SEND" onClick={notification} />
          <ToastContainer />
        </div>
      </form>
    </div>
  );
}
