
import React from 'react';

const Footer = () => {
		return(
				<footer>&copy; Sure & Pure LLC. All rights reserved.</footer>
		);
}

export default Footer;
